const LocalHost = "https://localhost:7140/api";
const ServerDomain = "https://cloudapp.ttdesignco.com";
const ScheduleDomain = "https://schedule-api.ttdesignco.com";
const ScheduleExcelDomain = "https://schedule-tool.ttdesignco.com";

const UrlConstant = {
  CLOUD_APP_API_URL: `${ServerDomain}/api`,
  SCHEDULE_APP_API_URL: `${ScheduleDomain}/api`,
  SCHEDULE_EXCEL_API_URL: `${ScheduleExcelDomain}/api`,
  CLOUD_APP_HOST_URL: `${ServerDomain}`,
};

export default UrlConstant;
